<template>
  <div
    class="product-card-wrapper"
    :data-test-id="wrapperDataTestId"
    :class="{
      'product-card-wrapper--slide': isSlide,
      'product-card-wrapper--promo': isPromoCard,
      'product-card-wrapper--on-wishlist': isOnWishlist,
    }"
  >
    <NuxtLink
      class="product-card"
      :to="localePath(link)"
      :target="openInNewTab ? '_blank' : '_self'"
    >
      <ProductCardImage v-bind="props" :image="props.image">
        <ProductCardWishlist
          v-if="!isPromoCard"
          :is-on-wishlist="isOnWishlist"
          @click.stop="handleClickWishlist"
        />
      </ProductCardImage>

      <div class="product-card__content">
        <div
          v-if="title"
          class="product-card__title"
          data-test-id="kft-product-card-title"
        >
          {{ title }}
        </div>
        <span v-if="isFeatureEnabled('next-date-on-product-card')">
          {{ nextEventDateTime }}
        </span>
        <ProductCardInformation v-bind="props" />
        <div v-if="regularPrice" class="product-card__price">
          <div
            v-if="specialPrice"
            :class="{ 'product-card__price__discounted-price': specialPrice }"
          >
            {{ formatPriceAmount(specialPrice, locale) }}
          </div>
          <div :class="{ 'product-card__price__original-price': specialPrice }">
            {{ formatPriceAmount(regularPrice, locale) }}
          </div>
          <span
            v-if="hasShippingCosts"
            class="text-gray-400 text-xs self-center"
            >{{ $t('product.show.checkout.shipping.productCardInfo') }}
          </span>
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { formatPriceAmount } from '@/utils/currencyHelpers'
import type { ProductCardImageBadge } from '~/components/product/ProductCardImage.vue'

const { isFeatureEnabled } = useFeatureToggle()
const localePath = useLocalePath()
const { locale } = useI18n()

interface Props {
  openInNewTab?: boolean
  image?: string | string[] | Record<string, any>
  badges?: ProductCardImageBadge[]
  productType?: string
  duration?: string
  durationTitle?: string
  isDurationVisible?: boolean
  rating?: number | string | null
  nextEventDateTime?: string
  productCategory?: string
  title?: string
  alt?: string
  link?: string
  linkTag?: string | undefined
  scoreRating?: number | boolean
  handleClick?: boolean
  reviewsCount?: number | boolean
  maxRating?: number | string
  regularPrice?: number | string | null
  specialPrice?: number | string | null
  isOnWishlist?: boolean
  isPromoCard?: boolean
  isSpecialGiftcard?: boolean
  hasShippingCosts?: boolean
  minNumberOfPeople?: number
  maxNumberOfPeople?: number
  tags?: string[]
  isSlide?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  openInNewTab: false,
  image: '',
  badges: () => [],
  productType: '',
  duration: '',
  durationTitle: '',
  isDurationVisible: true,
  rating: null,
  nextEventDateTime: '',
  productCategory: '',
  title: '',
  alt: '',
  link: '',
  linkTag: undefined,
  scoreRating: false,
  handleClick: false,
  reviewsCount: false,
  maxRating: 5,
  regularPrice: null,
  specialPrice: null,
  isOnWishlist: false,
  isPromoCard: false,
  isSpecialGiftcard: false,
  hasShippingCosts: false,
  tags: () => [],
  isSlide: false,
  minNumberOfPeople: 0,
  maxNumberOfPeople: 0,
})

const emit = defineEmits(['click:wishlist'])

const wrapperDataTestId = computed(() => {
  if (props.isSpecialGiftcard) {
    return 'special-giftcard-product-card'
  }

  return props.isPromoCard ? 'promotion-card' : 'product-card'
})

const handleClickWishlist = (event) => {
  event.preventDefault()
  emit('click:wishlist')
}
</script>

<style lang="postcss" scoped>
.product-card-wrapper {
  @apply flex relative;

  &:not(&--slide) {
    @apply w-full;
  }

  &--slide {
    @apply h-auto min-w-[170px];
    width: 100%;
  }

  .swiper-slide & {
    height: 100%;
  }
}

.product-card {
  @apply w-full flex md:flex-col relative bg-white p-1 ease-in-out duration-300 rounded-xl overflow-hidden;
  border: 1px solid #f3f4f6;

  .product-card-wrapper--promo & {
    @apply text-white;
    background: rgba(252, 255, 238, 0.65);
  }

  .swiper-slide & {
    @apply flex-col;
  }

  &:hover {
    box-shadow: 0 4px 16px 0 rgba(31, 42, 55, 0.05);

    @apply md:translate-y-[-3px];
  }

  &__content {
    width: calc(100% - 172px);
    @media (min-width: 0px) and (max-width: 365px) {
      width: calc(100% - 150px);
    }

    @apply pl-2 md:px-1 md:w-full flex flex-col md:pt-1.5 grow;

    /* styles for swiper slide */

    .swiper-slide & {
      @apply w-full p-0 pt-1.5;
    }
  }

  &__title {
    @apply font-medium duration-300 text-stone-700 text-sm md:text-base mb-1;
    line-height: 1.3;
  }

  &__price {
    @apply font-medium text-sm md:text-base mb-0 mt-1 text-stone-700 flex gap-1 items-end;
    letter-spacing: 0.01em;

    &__original-price {
      @apply line-through text-xs leading-5 text-gray-500;
    }

    &__discounted-price {
      @apply font-semibold text-danger;
    }
  }
}
</style>
